import { generateUrlParams } from "../../utils/generateUrlParams";
const baseUrl = "Application";

export const checkAdmissionStatusUrl = (jambRegNumber) =>
	`${baseUrl}/check-admission-status?RegNo=${jambRegNumber}`;
export const getAllApplicationTypesUrl = (filter) =>
	`${baseUrl}/all-application-types?${generateUrlParams(filter)}`;
export const createApplicationTypeUrl = () =>
	`${baseUrl}/create-application-type`;
export const editApplicationTypeUrl = (id) =>
	`${baseUrl}/update-application-types?id=${id}`;
export const toggleApplicationStatusUrl = (id) =>
	`${baseUrl}/toggle-application-type-status?id=${id}`;
export const deleteApplicationTypeUrl = (id) =>
	`${baseUrl}/delete-application-type?id=${id}`;
export const getApplicationUrl = (id, rrr) =>
	`${baseUrl}/applications?ApplicationTypeId=${id}&RRR=${rrr}`;
export const resetApplicationsUrl = () => `${baseUrl}/reset-applications`;
export const updateApplicationInvoiceUrl = () =>  `${baseUrl}/update-application-invoice`;
