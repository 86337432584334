import {
	ApplicationsRouter,
	CourseMangementRouter,
	FeesAssignmentRouter,
	LecturerManagementRouter,
	MainRouter,
	ReportsRouter,
	UnathorisedRouter,
	StudentManagementRouter,
	UserManagementRouter,
	InvoiceRouter,
	ResultManagementRouter,
	ApplicationReportsRouter
} from "../routers/index";
import { lazy } from "react";

const LazyDashboard = lazy(() =>
	import("../../pages/shared/Dashboard/dashboard")
);
const LazyStaffProfile = lazy(() =>
	import("../../pages/shared/Profile/profile")
);
const LazyChangePassword = lazy(() =>
	import("../../pages/shared/ChangePassword/changePassword")
);
const LazyAssignCourse = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/AssignCourse/assignCourse"
	)
);

const LazyManageCourse = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/ManageCourse/manageCourse"
	)
);

const LazyManageCourseUnit = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/ManageUnitLoad/manageUnitLoad"
	)
);

const LazyAddDropCourse = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/AddDropCourse/addDropCourse"
	)
);

const LazyAssignCreditLoad = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/AssignCreditLoad/assignCreditLoad"
	)
);

const LazyOpenCloseCourseRegistration = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/OpenCloseCourseRegistration/openCloseCourseRegistration"
	)
);

const LazySchoolFeesAssignment = lazy(() =>
	import("../../pages/superAdmin/FeesAssignment/pages/SchoolFees/schoolFees")
);

const LazyAcceptanceFeeAssignment = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/AcceptanceFee/acceptanceFee"
	)
);

const LazyPGSchoolFeesAssignment = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/PGSchoolFees/pgSchoolFees"
	)
);

const LazyLateRegistration = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/LateRegistration/lateRegistration"
	)
);

const LazyAssignCourseToLecturer = lazy(() =>
	import(
		"../../pages/superAdmin/LectuereManagement/pages/CourseAssignmentToLecturer/assignCourseToLecturer"
	)
);

const LazyAssignHOD = lazy(() =>
	import(
		"../../pages/superAdmin/LectuereManagement/pages/AssignHOD/assignHOD"
	)
);

const LazyAssignDean = lazy(() =>
	import(
		"../../pages/superAdmin/LectuereManagement/pages/AssignDean/assignDean"
	)
);

const LazyLecturerUpload = lazy(() =>
	import(
		"../../pages/superAdmin/LectuereManagement/pages/LecturerUpload/lecturerUpload"
	)
);

const LazySelectCourseRecords = lazy(() =>
	import(
		"../../pages/superAdmin/ApproveCourses/pages/SelectRecords/selectRecords"
	)
);

const LazyViewCoursesRegistered = lazy(() =>
	import(
		"../../pages/superAdmin/ApproveCourses/pages/ViewCoursesRegistered/viewCoursesRegistered"
	)
);

const LazyClassListResultRecords = lazy(() =>
	import(
		"../../pages/superAdmin/ResultManagement/pages/CLassList/SelectRecords/selectRecords"
	)
);

const LazyGSCourseUploadRecords = lazy(() =>
	import(
		"../../pages/superAdmin/ResultManagement/pages/GSCourseUpload/SelectRecords/selectRecords"
	)
);

const LazyViewAdmissionList = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/AdmissionList/ViewAdmissionList"
	)
);

const LazyAssignCourseAdviser = lazy(() =>
	import(
		"../../pages/superAdmin/LectuereManagement/pages/AssignCourseAdviser/assignCourseAdviser"
	)
);

const LazyVerifyRemitaStatus = lazy(() =>
	import(
		"../../pages/superAdmin/InvoiceManagement/pages/VerifyRemitaStatus/pages/VerifyRemitaStatus"
	)
);

const LazyClasslistViewResultsUploaded = lazy(() =>
	import(
		"../../pages/superAdmin/ResultManagement/pages/CLassList/ViewResults/viewResults"
	)
);


const LazyManageInvoices = lazy(() => 
	import(
		"../../pages/superAdmin/Application/ManageInvoices/manageInvoices"
	)
);

const LazyInvoiceManagement = lazy(() =>
	import(
		"../../pages/superAdmin/InvoiceManagement/pages/InvoiceManagement/invoiceManagement"
	)
);

const LazyPayments = lazy(() =>
	import(
		"../../pages/superAdmin/Payments/payments"
	)
);

const LazyDeleteSundryInvoice = lazy(() =>
	import(
		"../../pages/superAdmin/InvoiceManagement/pages/DeleteSundryInvoice/pages/deleteSundryInvoice"
	)
);

const LazyDeletePGInvoice = lazy(() =>
	import(
		"../../pages/superAdmin/InvoiceManagement/pages/DeletePGInvoice/pages/deletePGInvoice"
	)
);

const LazyFeeBypass = lazy(() =>
	import("../../pages/superAdmin/InvoiceManagement/pages/FeeBypass/feeBypass")
);

const LazyManageRoles = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageRoles/manageRoles"
	)
);

const LazyManageUsers = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageUsers/manageUsers"
	)
);

const LazyUserClaimManagement = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageUsers/userClaimManagement"
	)
);

const LazyManageMenus = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageMenus/manageMenus"
	)
);

const LazyMenuManagement = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageMenus/menuManagement"
	)
);

const LazyViewAllStudents = lazy(() =>
	import("../../pages/superAdmin/StudentManagement/Students/ViewAllStudents")
);

const LazySessionManager = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/SessionManager/sessionManager"
	)
);

const LazyEditProfile = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/Students/ViewProfile/viewProfile"
	)
);

const LazyClearStudents = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/ClearStudents/clearStudents"
	)
);

const LazyNoticeBoard = lazy(() =>
	import("../../pages/superAdmin/NoticeBoard/noticeBoard")
);

const LazyApplicationSetup = lazy(() =>
	import(
		"../../pages/superAdmin/Application/ApplicationSetup/applicationSetup"
	)
);

const LazyApplicationReset = lazy(() =>
	import(
		"../../pages/superAdmin/Application/ApplicationReset/pages/applicationReset"
	)
);


const LazyFilterClassRecords = lazy(() =>
	import("../../pages/lecturer/MyClass/pages/FilterRecords/filterRecords")
);

const LazyViewClassRecords = lazy(() =>
	import("../../pages/lecturer/MyClass/pages/ViewRecords/viewRecords")
);

const LazyViewClassResults = lazy(() =>
	import("../../pages/lecturer/MyClass/pages/ViewResults/viewResults")
);



const LazyViewStudentResult = lazy(() =>
	import("../../pages/student/Results/pages/ViewResult/viewResult")
);

const LazyDeleteInvoice = lazy(() =>
	import(
		"../../pages/superAdmin/Application/DeleteInvoice/pages/deleteInvoice"
	)
);
const LazyJambListUpload = lazy(() =>
	import("../../pages/superAdmin/Application/JambListUpload/jambListUpload")
);
const LazyDirectEntryUpload = lazy(() =>
	import(
		"../../pages/superAdmin/Application/DirectEntryUpload/directEntryUpload"
	)
);
const LazySundryReport = lazy(() =>
	import("../../pages/superAdmin/Reports/pages/SundryReport/sundryReport")
);
const LazyPaymentReports = lazy(() =>
	import("../../pages/superAdmin/Reports/pages/PaymentReports/paymentReports")
);
const LazyAdmissionListReports = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/AdmissionListReports/admissionListReports"
	)
);

const LazyLecturerResultReports = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/LecturerResultReports/lecturerResultReports"
	)
);

const LazyApplicationReports = lazy(() =>
	import(
		"../../pages/superAdmin/ApplicationReports/pages/ApplicationReports/applicationReports"
	)
);
const LazySchoolFeesReport = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/SchoolFeesReport/pages/schoolFeesReport"
	)
);
const LazyPutmeReports = lazy(() =>
	import(
		"../../pages/superAdmin/ApplicationReports/pages/PutmeReports/putmeReports"
	)
);
const LazyJupebReports = lazy(() =>
	import(
		"../../pages/superAdmin/ApplicationReports/pages/JupebReports/jupebReports"
	)
);
const LazyPGReports = lazy(() =>
	import(
		"../../pages/superAdmin/ApplicationReports/pages/PGReports/pgReports"
	)
);
const LazyPartTimeReports = lazy(() =>
	import(
		"../../pages/superAdmin/ApplicationReports/pages/PartTimeReports/partTimeReports"
	)
);
const LazyDEReports = lazy(() =>
	import(
		"../../pages/superAdmin/ApplicationReports/pages/DEReports/DEReports"
	)
);
const LazyFilterRecords = lazy(() =>
	import("../../pages/lecturer/Records/pages/FilterRecords/filterRecords")
);
const LazyViewRecords = lazy(() =>
	import("../../pages/lecturer/Records/pages/ViewRecords/viewRecords")
);
const LazyViewResults = lazy(() =>
	import("../../pages/lecturer/Records/pages/ViewResults/viewResults")
);
const LazyViewClassList = lazy(() =>
	import("../../pages/lecturer/Records/pages/ViewClassList/viewClassList")
);
const LazyUnauthorized = lazy(() =>
	import("../../pages/shared/Unauthorized/unauthorized")
);
const LazyBusaryFees = lazy(() =>
	import("../../pages/superAdmin/FeesAssignment/pages/BusaryFee/busaryFees")
);

const LazyStudentClaimManagement = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/Students/StudentClaimManagement"
	)
);

const LazyBursaryInvoiceManagement = lazy(() =>
	import(
		"../../pages/superAdmin/InvoiceManagement/pages/bursaryInvoiceManagement/bursaryInvoiceManagement"
	)
);

export const staffRoutes = [
	//add staff routes here
	{
		path: "/dashboard",
		component: LazyDashboard,
		exact: true,
		router: MainRouter,
		title: "Dashboard"
	},
	{
		path: "/profile",
		component: LazyStaffProfile,
		exact: true,
		router: MainRouter,
		title: "Profile"
	},
	{
		path: "/course_management/manage_course",
		component: LazyManageCourse,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/assign_course",
		component: LazyAssignCourse,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/manage_load",
		component: LazyManageCourseUnit,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/add_drop",
		component: LazyAddDropCourse,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/assign_unit",
		component: LazyAssignCreditLoad,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/fees_assignment/school_fees",
		component: LazySchoolFeesAssignment,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/acceptance_fees",
		component: LazyAcceptanceFeeAssignment,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/pg_school_fees",
		component: LazyPGSchoolFeesAssignment,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/late_registration",
		component: LazyLateRegistration,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/busary_fees",
		component: LazyBusaryFees,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/course_management/open_reg",
		component: LazyOpenCloseCourseRegistration,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/invoice_management/verify_remita_status",
		component: LazyVerifyRemitaStatus,
		exact: true,
		router: InvoiceRouter,
		title: "Invoice Management"
	},
	{
		path: "/lecturer_management/assign_courses",
		component: LazyAssignCourseToLecturer,
		exact: true,
		router: LecturerManagementRouter,
		title: "Assign Course To Lecturer"
	},
	{
		path: "/lecturer_management/assign_staff/hod",
		component: LazyAssignHOD,
		exact: true,
		router: LecturerManagementRouter,
		title: "Assign HOD"
	},
	{
		path: "/lecturer_management/assign_staff/dean",
		component: LazyAssignDean,
		exact: true,
		router: LecturerManagementRouter,
		title: "Assign Dean"
	},
	{
		path: "/lecturer_management/lecturer_upload",
		component: LazyLecturerUpload,
		exact: true,
		router: LecturerManagementRouter,
		title: "Lecturer Upload"
	},
	{
		path: "/approve_courses",
		component: LazySelectCourseRecords,
		exact: true,
		router: MainRouter,
		title: "Approve & Unapprove Courses"
	},
	{
		path: "/approve_courses/view",
		component: LazyViewCoursesRegistered,
		exact: true,
		router: MainRouter,
		title: "Approve & Unapprove Courses"
	},
	{
		path: "/results/classlist",
		component: LazyClassListResultRecords,
		exact: true,
		router: ResultManagementRouter,
		title: "Result Management"
	},
	{
		path: "/results/classlist/view",
		component: LazyClasslistViewResultsUploaded,
		exact: true,
		router: MainRouter,
		title: "Result Management"
	},
	{
		path: "/results/gs_course_upload",
		component: LazyGSCourseUploadRecords,
		exact: true,
		router: ResultManagementRouter,
		title: "Result Management"
	},
	{
		path: "/student_management/admission_list/view",
		component: LazyViewAdmissionList,
		exact: true,
		title: "Admission List",
		router: StudentManagementRouter
	},
	{
		path: "/student_management/admission_list/search",
		component: LazyViewAdmissionList,
		exact: true,
		title: "Search Admission List",
		router: StudentManagementRouter
	},

	{
		path: "/invoice_management/invoice",
		component: LazyInvoiceManagement,
		exact: true,
		router: InvoiceRouter,
		title: "Invoice Management"
	},
	{
		path: "/invoice_management/fee_bypass",
		component: LazyFeeBypass,
		exact: true,
		router: InvoiceRouter,
		title: "Invoice Management"
	},
	{
		path: "/invoice_management/delete_sundry_invoice",
		component: LazyDeleteSundryInvoice,
		exact: true,
		router: InvoiceRouter,
		title: "Invoice Management"
	},
	{
		path: "/invoice_management/delete_pg_invoice",
		component: LazyDeletePGInvoice,
		exact: true,
		router: InvoiceRouter,
		title: "Invoice Management"
	},
	{
		path: "/user_management/roles",
		component: LazyManageRoles,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/users",
		component: LazyManageUsers,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},

	{
		path: "/class",
		component: LazyFilterClassRecords,
		exact: true,
		router: MainRouter,
		title: "My Class"
	},

	{
		path: "/class/view",
		component: LazyViewClassRecords,
		exact: true,
		router: MainRouter,
		title: "My Class"
	},

	{
		path: "/class/results",
		component: LazyViewClassResults,
		exact: true,
		router: MainRouter,
		title: "My Class"
	},

	{
		path: "/class/results/student",
		component: LazyViewStudentResult,
		exact: true,
		router: MainRouter,
		title: "My Class"
	},

	{
		path: "/class/classList",
		component: LazyViewClassList,
		exact: true,
		router: MainRouter,
		title: "My Class"
	},


	{
		path: "/class/approve_courses",
		component: LazyViewCoursesRegistered,
		exact: true,
		router: MainRouter,
		title: "Approve & Unapprove Courses"
	},


	{
		path: "/user_management/users/edit",
		component: LazyEditProfile,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/users/management",
		component: LazyUserClaimManagement,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/menus",
		component: LazyManageMenus,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/menus/management",
		component: LazyMenuManagement,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/student_management/view",
		component: LazyViewAllStudents,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/search_student",
		component: LazyViewAllStudents,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/view/edit",
		component: LazyEditProfile,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/manage_claims",
		component: LazyStudentClaimManagement,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/clear",
		component: LazyClearStudents,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/session_manager",
		component: LazySessionManager,
		exact: true,
		router: StudentManagementRouter,
		title: "Session Manager"
	},
	{
		path: "/notice_board_management",
		component: LazyNoticeBoard,
		exact: true,
		router: MainRouter,
		title: "Notice board setup"
	},
	{
		path: "/applications/setup",
		component: LazyApplicationSetup,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/applications/reset",
		component: LazyApplicationReset,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/applications/delete",
		component: LazyDeleteInvoice,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/applications/jamb_list_upload",
		component: LazyJambListUpload,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/applications/de_list_upload",
		component: LazyDirectEntryUpload,
		exact: true,
		router: ApplicationsRouter,
		title: "Direct Entry Upload"
	},
	{
		path: "/reports/sundry",
		component: LazySundryReport,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/school_fees",
		component: LazySchoolFeesReport,
		exact: true,
		router: ReportsRouter,
		title: "School Fees Report"
	},
	{
		path: "/reports/payments",
		component: LazyPaymentReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/admission_list",
		component: LazyAdmissionListReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},

	{
		path: "/reports/lecturer_result",
		component: LazyLecturerResultReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},



	{
		path: "/lecturer_management/assign_staff/course_adviser",
		component: LazyAssignCourseAdviser,
		exact: true,
		router: LecturerManagementRouter,
		title: "Assign Course Adviser"
	},


	{
		path: "/app_reports/application_reports",
		component: LazyApplicationReports,
		exact: true,
		router: ApplicationReportsRouter,
		title: "Application Reports"
	},
	{
		path: "/app_reports/putme_reports",
		component: LazyPutmeReports,
		exact: true,
		router: ApplicationReportsRouter,
		title: "Application Reports"
	},
	{
		path: "/app_reports/jupeb_reports",
		component: LazyJupebReports,
		exact: true,
		router: ApplicationReportsRouter,
		title: "Application Reports"
	},
	{
		path: "/app_reports/pg_reports",
		component: LazyPGReports,
		exact: true,
		router: ApplicationReportsRouter,
		title: "Application Reports"
	},
	{
		path: "/app_reports/part_time_reports",
		component: LazyPartTimeReports,
		exact: true,
		router: ApplicationReportsRouter,
		title: "Application Reports"
	},
	{
		path: "/app_reports/de_reports",
		component: LazyDEReports,
		exact: true,
		router: ApplicationReportsRouter,
		title: "Application Reports"
	},
	{
		path: "/records",
		component: LazyFilterRecords,
		exact: true,
		router: MainRouter,
		title: "My Courses"
	},
	{
		path: "/records/view",
		component: LazyViewRecords,
		exact: true,
		router: MainRouter,
		title: "My Courses"
	},
	{
		path: "/records/results",
		component: LazyViewResults,
		exact: true,
		router: MainRouter,
		title: "My Courses"
	},
	{
		path: "/records/classList",
		component: LazyViewClassList,
		exact: true,
		router: MainRouter,
		title: "My Courses"
	},
	{
		path: "/no_access",
		component: LazyUnauthorized,
		exact: true,
		router: UnathorisedRouter,
		title: "Unauthorized access"
	},
	{
		path: "/change_password",
		component: LazyChangePassword,
		exact: true,
		router: MainRouter,
		title: "Change Password"
	},
	{
		path: "/applications/manage_invoices",
		component: LazyManageInvoices,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/invoice_management/bursary",
		component: LazyBursaryInvoiceManagement,
		exact: true,
		router: InvoiceRouter,
		title: "Invoice Management"
	},
	{
		path: "/payments",
		component: LazyPayments,
		exact: true,
		router: MainRouter,
		title: "Payments"
	},
];
